<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="arrow-btn">
          <img
            src="../assets/img/arrowBack.svg"
            class="img-pluss-btn"
            alt="arrow-back"
          />
        </div>
        <!-- <div class="vertical-line-1"></div> -->
        <div class="add-btn" @click="createItem">
          <p class="add-text">Добавить ,блюдо</p>
          <img src="../assets/img/plusAdd.svg" alt="plus" />
        </div>
      </v-col>
    </v-row>

    <div id="menu">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="products"
              :search="search"
              :footer-props="footerProps"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title"
                    >Меню
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                  <v-autocomplete
                    v-model="selectedItem"
                    outlined
                    :items="filteredAccounts"
                    label="Фильтр по ресторанам"
                    hide-details
                    solo
                    clearable
                    item-text="name"
                    item-value="id"
                    placeholder="Фильтр по ресторанам"
                    :filter="customFilter"
                    class="custom-scroll"
                  >
                  <template v-slot:item="{ item }">
                  <!-- Простой элемент списка -->
                      <div class="filter-menu-item">{{ item.name }}</div>
                  </template>
                </v-autocomplete>
                </div>
                <div class="search-component">
                  <v-autocomplete
                    v-model="selectedItem"
                    outlined
                    :items="filteredAccounts"
                    label="Фильтр по категориям"
                    hide-details
                    solo
                    clearable
                    item-text="name"
                    item-value="id"
                    placeholder="Фильтр по категориям"
                    :filter="customFilter"
                    class="custom-scroll"
                  >
                  <template v-slot:item="{ item }">
                  <!-- Простой элемент списка -->
                      <div class="filter-menu-item">{{ item.name }}</div>
                  </template>
                </v-autocomplete>
                </div>
                  <div class="search-component">
                    <input
                      type="search"
                      class="search-component__input mr-10"
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="Поиск"
                      single-line
                      hide-details
                    />
                    <svg width="24" height="24" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <!-- <v-text-field
                    class="mr-10"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                  ></v-text-field> -->
                  <!-- <button id="staff-add-button" @click="createItem">
                    Создать новый ресторан +
                  </button> -->
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        Персонал
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Полное имя</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea
                                class="col-description"
                                v-model="fullName"
                                ref="fullNameTextarea"
                                :placeholder="
                                  isfullNameFocused ? '' : textareaPlaceholder
                                "
                                @focus="isfullNameFocused = true"
                                @blur="isfullNameFocused = false"
                              ></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="col-content-2 px-0">
                          <v-col
                            cols="2"
                            class="col-paddings-r-l px-0 py-0 role-margin-1"
                          >
                            <p class="col-title">Роль</p>
                          </v-col>
                          <v-col cols="1" class="py-0">
                            <span class="red-dot">*</span>
                          </v-col>
                          <!-- <v-select
                            v-model="newItem.applicationType"
                            :items="applicationTypes"
                            label="Выберите тип приложения"
                            item-text="label"
                            item-value="value"
                          /> -->
                          <v-col cols="9" class="px-0 py-0">
                            <select
                              v-model="selectedRoles"
                              class="custom-select"
                            >
                              <option
                                v-for="item in roleTranslations"
                                :value="item"
                                :key="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                            <div class="select-arrow"></div>
                          </v-col>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="col-content-2 px-0">
                          <v-col
                            cols="2"
                            class="col-paddings-r-l px-0 py-0 role-margin"
                          >
                            <p class="col-title">Ресторан</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-2">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="9" class="py-0 px-0">
                            <p class="text-limit">
                              Coffeeon Saigon ул. Кабанбай батыра, 122
                            </p>
                          </v-col>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0">
                          <div class="col-content px-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Телефон</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <input
                                class="col-description"
                                v-model="phone"
                                v-mask="'+7 (###) ###-##-##'"
                                ref="phoneTextarea"
                                :placeholder="
                                  isphoneFocused ? '' : textareaPlaceholder
                                "
                                @focus="phoneFocused = true"
                                @blur="phoneFocused = false"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div class="btn-container">
                        <div class="delete-btn" text @click="createItemConfirm">
                          Деактивировать
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div class="save-btn" text @click="createItemConfirm">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Вы точно хотите удалить данное
                        приложение?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Отменить</v-btn
                        >
                        <v-btn
                          color="red darken-1"
                          text
                          @click="deleteItemConfirm"
                          >Удалить</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td @click="editItem(item)">{{ item.name }}</td>
                  <td @click="editItem(item)">{{ item.category.name }}</td>
                  <td @click="editItem(item)">{{ item.description }}</td>
                  <td @click="editItem(item)">{{ item.price }} ₸</td>
                  <td @click="editItem(item)">{{ item.category.name }}</td>
                  <td @click="editItem(item)">{{ item.category.name }}</td>
                  <td style="text-align: right;">
                    <v-icon medium @click="deleteItem(item)"
                      >mdi-dots-vertical</v-icon
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
              <!-- <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во персонала на странице:
                    <v-select
                      class="custom-select"
                      v-model="itemsPerPage"
                      :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage"
                      hide-details
                    ></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button
                      type="button"
                      :disabled="currentPage === 1"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === 1 }"
                      aria-label="Предыдущая страница"
                      @click="goToPreviousPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-left theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button
                      type="button"
                      :disabled="currentPage === totalPages"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === totalPages }"
                      aria-label="Следующая страница"
                      @click="goToNextPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-right theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { debounce } from 'lodash';
export default {
  directives: { mask },
  name: "Menu",

  data: () => ({
    isPlaceholderHidden: false,
    isfullNameFocused: false,
    isphoneFocused: false,
    selectedRoles: null,
    fullName: "",
    phone: "",
    dialogDelete: false,
    dialogCreate: false,
    search: "",
    roleTranslations: {
      Owner: 'Владелец',
      Administrator: 'Администратор'
    },
    newItem: {
      name: "",
      rolesTest: null,
      restaurant: "",
      phone: "",
    },
    selectedType: null,
    rolesTest: [
      { label: "CustomerApp", value: 1 },
      { label: "RestaurantApp", value: 2 },
      { label: "WebApp", value: 3 },
    ],
    headers: [
      {
        text: "Название ресторана",
        align: "start",
        value: "name",
      },
      { text: "Название блюда", value: "category.name" },
      { text: "Категория", value: "category.name" },
      { text: "Описание", value: "description" },
      { text: "Цена", value: "price" },
      { text: "Статус", value: "category.name" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    products: [],
    restaurantId: "",
    profiles: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    totalPages: 0,
  }),

  created() {
    this.initialize();
    // this.profileInfo();
  },
  computed: {
    filteredStaffs() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.staffs.items.filter(
          // (item) => item.rolesTest === this.selectedType
        );
      } else {
        return this.staffs.items;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [3, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  methods: {
    getRoleTranslation(role) {
      return this.roleTranslations[role] || role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "fullName") {
        this.isfullNameFocused = true;
      }
      // } else if (inputName === "actualAdress") {
      //   this.isActualAdressFocused = true;
      // }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
    },
    initialize() {
      this.$http
        .get(`/api/Product/GetRestaurantProducts`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            restaurantId: this.user.currentRest.restaurant.id
          }
        })
        .then((response) => {
          this.products = response.data;// Общее количество элементов
        })
        .catch((error) => (this.error = error));
    },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },

    deleteItem(item) {
      this.editedIndex = this.applications.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/api/Application/${this.editedItem.id}`)
        .then(() => {
          this.applications.items.splice(this.editedIndex, 1);
          this.closeDelete();

          // Здесь мы устанавливаем флаг showSnackbar в true,
          // чтобы уведомление показалось после завершения удаления
          this.showSnackbar = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
    },

    closeCreate() {
      this.dialogCreate = false;
    },

    createItemConfirm() {
      this.$http
        .post("/api/Application", this.newItem)
        .then(() => {
          this.initialize();
          this.dialogCreate = false;
          this.newItem = {
            name: "",
            rolesTest: "",
            phone: "",
            restaurant: "",
          };
        })
        .catch((error) => {
          this.error = error;
        });
    },

    editItem(item) {
      this.$router.push({ path: `/ProductPage/${item.id}` });
    },
  },

  watch: {
    fullName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("fullNameTextarea");
      });
    },
    search: debounce(function() {
    // Выполняем запрос с задержкой после изменения переменной поиска
    this.initialize();
  }, 500),

    phone() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("phoneTextarea");
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-autocomplete__content.v-menu__content {
  color: #000;
  background-color: #000;
  background: #000;
}
  ::v-deep .v-select__slot {
    border: none;
  }
  ::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
  }
  ::v-deep .v-autocomplete.v-input--outlined .v-input__control {
  border: none !important;
}
  ::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot 
 {
  box-shadow: none!important;
  border: 1px solid rgb(224,224,224);
}
::v-deep .v-text-field--outlined fieldset {
  border: none !important;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 45px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}
::v-deep .v-autocomplete__content.v-menu__content .v-select-list {
    border-radius: 0 0 4px 4px;
    background: green;
}
  ::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}
::v-deep .v-list-item:first-child {
  border-bottom: none;
}
::v-deep .v-list .v-list-item--active {
  color: black;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* Толщина, стиль и цвет левой границы */
}

::v-deep .v-autocomplete__content input {
  border: none; /* Убираем рамку */
}

::v-deep  .v-autocomplete__content .v-list-item__title {
  color: black; /* Цвет текста */
}

/* Стили для скрытия рамки при наведении */
::v-deep  .v-autocomplete__content .v-list-item:hover {
  border: none;
}

/* Применяем ваши стили к полю ввода */
::v-deep .v-autocomplete__content input {
  border: 1px solid rgb(224,224,224);
  border-radius: 5px;
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
}
::v-deep .v-menu__content > .v-select__content::-webkit-scrollbar {
  width: 8px; /* Ширина скроллбара */
}

::v-deep .v-menu__content > .v-select__content::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет ползунка */
}

::v-deep .v-menu__content > .v-select__content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона скроллбара */
}
::v-deep .v-menu__content {
  max-height: none;
}
::v-deep .v-autocomplete__content >.v-menu__content > .v-select-list {
  color: pink !important;
  background-color: #00b800 !important;
  margin: 100px;
}
#menu > .container{
    border-radius: 8px;
  }
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 46px;
  align-items: center;
}
.red-dot-margin-2 {
  margin-left: -17px;
}
.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}
.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}
.text-limit {
  width: 30ch; /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
}
.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333; /* Цвет стрелочки */
}
.col-description,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}
.role-margin {
  margin-right: 37px;
}
.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}
.col-description {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  /* min-height: 20px; */
  width: 100%;
  /* margin-left: 18px; */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: #Fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 12px;
    transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  color: white; 
}
.add-btn {
  display: flex;
    align-items: center;
    padding: 5px 18px 5px 18px;
    margin-left: 37px;
    cursor: pointer;
    border-radius: 5px;
    background: rgb(50, 60, 77);
    height: 36px;
}
.top-panel {
  height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}
.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.search-component {
  max-width: 430px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: row;
    margin-left: 40px;
}
.search-component__input {
  border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    width: 100%;
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 14.5px;
  right: 55px;
}
#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}
::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
    border-bottom: thin solid #848484;
  color: rgb(96, 108, 128);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th
  > span {
  color: rgb(96, 108, 128);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>

